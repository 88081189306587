import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  MDBInput,
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBSideNavItem,
  MDBSideNavCat,
  MDBSideNavNav,
  MDBSideNav,
  MDBContainer
} from 'mdbreact';

class DoubleNavigationPage extends Component {
  state = {
    toggleStateA: false,
    breakWidth: 1300,
    windowWidth: 0
  };

  componentDidMount = () => {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.handleResize);
  };

  handleResize = () =>
    this.setState({
      windowWidth: window.innerWidth
    });

  handleToggleClickA = () => {
    const { toggleStateA } = this.state;
    this.setState({
      toggleStateA: !toggleStateA
    });
  };

  render() {
    const { breakWidth, toggleStateA, windowWidth } = this.state;
    const navStyle = {
      paddingLeft: windowWidth > breakWidth ? '210px' : '16px'
    };
    const mainStyle = {
      margin: '0 6%',
      paddingTop: '5.5rem',
      paddingLeft: windowWidth > breakWidth ? '240px' : '0'
    };
    const specialCaseNavbarStyles = {
      WebkitBoxOrient: 'horizontal',
      flexDirection: 'row'
    };
    return (
      <Router>
        <div className='fixed-sn light-blue-skin'>
          <MDBSideNav
            logo='https://mdbootstrap.com/img/logo/mdb-transparent.png'
            triggerOpening={toggleStateA}
            breakWidth={breakWidth}
            bg='https://mdbootstrap.com/img/Photos/Others/sidenav1.jpg'
            mask='strong'
            fixed
          >
            <li>
              <ul className='social'>
                <li>
                  <a href='#!'>
                    <MDBIcon brand icon='facebook' />
                  </a>
                </li>
                <li>
                  <a href='#!'>
                    <MDBIcon brand icon='pinterest' />
                  </a>
                </li>
                <li>
                  <a href='#!'>
                    <MDBIcon brand icon='google-plus' />
                  </a>
                </li>
                <li>
                  <a href='#!'>
                    <MDBIcon brand icon='twitter' />
                  </a>
                </li>
              </ul>
            </li>
            <MDBInput
              type='text'
              hint='Search'
              style={{
                color: '#fff',
                padding: '8px 10px 8px 30px',
                boxSizing: 'border-box'
              }}
            />
            <MDBSideNavNav>
              <MDBSideNavCat name='Submit blog' id='submit-blog-cat' icon='chevron-right'>
                <MDBSideNavItem>Submit listing</MDBSideNavItem>
                <MDBSideNavItem>Registration form</MDBSideNavItem>
              </MDBSideNavCat>
              <MDBSideNavCat name='Instruction' id='instruction-cat' icon='hand-pointer'>
                <MDBSideNavItem>For bloggers</MDBSideNavItem>
                <MDBSideNavItem>For authors</MDBSideNavItem>
              </MDBSideNavCat>
              <MDBSideNavCat name='About' id='about-cat' icon='eye'>
                <MDBSideNavItem>Instruction</MDBSideNavItem>
                <MDBSideNavItem>Monthly meetings</MDBSideNavItem>
              </MDBSideNavCat>
              <MDBSideNavCat name='Contact me' id='contact-me-cat' icon='envelope'>
                <MDBSideNavItem>FAQ</MDBSideNavItem>
                <MDBSideNavItem>Write a message</MDBSideNavItem>
              </MDBSideNavCat>
            </MDBSideNavNav>
          </MDBSideNav>
          <MDBNavbar style={navStyle} double expand='md' fixed='top' scrolling>
            <MDBNavbarNav left>
              <MDBNavItem>
                <div
                  onClick={this.handleToggleClickA}
                  key='sideNavToggleA'
                  style={{
                    lineHeight: '32px',
                    marginRight: '1em',
                    verticalAlign: 'middle'
                  }}
                >
                  <MDBIcon icon='bars' color='white' size='2x' />
                </div>
              </MDBNavItem>
              <MDBNavItem className='d-none d-md-inline' style={{ paddingTop: 5 }}>
                Material Design for Bootstrap
              </MDBNavItem>
            </MDBNavbarNav>
            <MDBNavbarNav right style={specialCaseNavbarStyles}>
              <MDBNavItem active>
                <MDBNavLink to='#!' link>
                  <MDBIcon icon='envelope' className='d-inline-inline' />{' '}
                  <div className='d-none d-md-inline'>Contact</div>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to='#!' link>
                  <MDBIcon icon='comments' className='d-inline-inline' />{' '}
                  <div className='d-none d-md-inline'>Support</div>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBNavLink to='#!' link>
                  <MDBIcon icon='user' className='d-inline-inline' /> <div className='d-none d-md-inline'>Account</div>
                </MDBNavLink>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <div className='d-none d-md-inline'>Dropdown</div>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu right>
                    <MDBDropdownItem href='#!'>Action</MDBDropdownItem>
                    <MDBDropdownItem href='#!'>Another Action</MDBDropdownItem>
                    <MDBDropdownItem href='#!'>Something else here</MDBDropdownItem>
                    <MDBDropdownItem href='#!'>Something else here</MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBNavbar>
          <main style={mainStyle}>
            <MDBContainer fluid style={{ height: 2000 }} className='mt-5'>
              <h2>Advanced Double Navigation with fixed SideNav & fixed Navbar:</h2>
              <br />
              <h5>1. Fixed side menu, hidden on small devices.</h5>
              <h5>2. Fixed Navbar. It will always stay visible on the top, even when you scroll down.</h5>
            </MDBContainer>
          </main>
        </div>
      </Router>
    );
  }
}

export default DoubleNavigationPage;
